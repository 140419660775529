var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.$store.state.globalShare.moreCommentPopupStatus,
        "custom-class": "moreCommentPopup",
        "append-to-body": true,
        "destroy-on-close": true,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.globalShare,
            "moreCommentPopupStatus",
            $event
          )
        },
        close: _vm.close
      }
    },
    [
      _c(
        "div",
        { staticClass: "titleStyle", attrs: { slot: "title" }, slot: "title" },
        [
          _vm._v(
            "共" + _vm._s(this.moreCommentPopupConfig.childrenCount) + "条回复"
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "moreCommentContent" },
        [
          _c("Comment", {
            attrs: {
              comment: _vm.moreCommentPopupConfig,
              replyInTheComponent: true
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }