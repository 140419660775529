<template>
  <el-dialog 
    :visible.sync="$store.state.globalShare.moreCommentPopupStatus" 
    custom-class="moreCommentPopup" 
    @close="close"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <div class="titleStyle" slot="title">共{{this.moreCommentPopupConfig.childrenCount}}条回复</div>
    <div class="moreCommentContent">
      <Comment :comment="moreCommentPopupConfig" :replyInTheComponent="true"></Comment>
    </div>
  </el-dialog>
</template>
<script>
import Comment from "./mainCommentForMoreWindow";
export default {
  components: {
    Comment
  },
  computed: {
    // 需要展示的评论信息
    moreCommentPopupConfig() {
      return this.$store.state.globalShare.moreCommentPopupConfig
    },
  },
  watch: {
    '$route': {
      handler(val,old) {
        // 路由切换后，就关闭弹窗
        this.close();
      },
      deep: true,
    }
  },
  methods: {
    close() {
      this.$store.dispatch('commitCloseMoreCommentPopup')
    }
  }
}
</script>
<style lang="stylus" scoped>


</style>
<style lang="stylus">
  .moreCommentPopup
    width 700px !important;
    height 74vh !important;
    margin-top 0 !important;
    display flex;
    flex-flow column;
    align-items center;
    justify-content flex-start;
    .el-dialog__header
      width 100%;
      box-sizing border-box;
      padding 20px;
      flex-shrink 0;
      .titleStyle
        color #373737;
        font-size 14px;
        font-weight bold;
        line-height 24px;
    .el-dialog__body
      width 100%;
      padding 0 !important;
      flex 1;
      min-height 0;
      .moreCommentContent
        width 100%;
        height 100%;
        overflow hidden;
        padding 0 30px 0 20px;

</style>